exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-coraly-js": () => import("./../../../src/pages/projects/coraly.js" /* webpackChunkName: "component---src-pages-projects-coraly-js" */),
  "component---src-pages-projects-diagnosticbiochips-js": () => import("./../../../src/pages/projects/diagnosticbiochips.js" /* webpackChunkName: "component---src-pages-projects-diagnosticbiochips-js" */),
  "component---src-pages-projects-docsity-js": () => import("./../../../src/pages/projects/docsity.js" /* webpackChunkName: "component---src-pages-projects-docsity-js" */),
  "component---src-pages-projects-rosmariano-js": () => import("./../../../src/pages/projects/rosmariano.js" /* webpackChunkName: "component---src-pages-projects-rosmariano-js" */),
  "component---src-pages-projects-vetrinalive-js": () => import("./../../../src/pages/projects/vetrinalive.js" /* webpackChunkName: "component---src-pages-projects-vetrinalive-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

