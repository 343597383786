const theme = {
  sizes: {
    container: 1200,
  },
  layout: {
    container: {
      px: "4",
      mx: "auto",
      maxWidth: "1200px",
      width: "100%",
      pb: "4",
    },
    paragraphProject: {
      width: ["100%", "100%", "70%"],
      mx: "auto",
    },
  },
  breakpoints: ["768px", "900px", "1200px"],
  space: [0, 4, 8, 16, 24, 32, 64, 80, 128, 256, 512],
  fonts: {
    default: "Inter",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#07c",
    secondary: "#30c",
    muted: "#757575",
    project: {
      docsity: "#b3d0ff",
      labbox: "#838B9F",
      coraly: "#FC9FB6",
      rosmariano: "#83CAAA",
      vetrinalive: "#6AD2FF",
    },
  },
  text: {
    root: {
      fontFamily: "default",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      color: "text",
      fontFamily: "default",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 6,
    },
    h2: {
      color: "text",
      fontFamily: "default",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
    },
    h3: {
      color: "text",
      fontFamily: "default",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 3,
    },
    smallTitle: {
      color: "text",
      fontFamily: "default",
      fontWeight: "heading",
      lineHeight: "body",
      fontSize: 3,
    },
    descriptionBig: {
      color: "text",
      fontFamily: "default",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: 3,
    },
    descriptionSmall: {
      color: "text",
      fontFamily: "default",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: 2,
    },
    a: {
      color: "#FFD600",
    },
    pre: {
      fontFamily: "default",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
    body: {
      margin: 0,
    },
  },
}

export default theme
